<template>
    <div class="">
        <div class="fl-x fl-a-e">
            <img :src="img" alt="" class="mr-2 avatar-img">
            <div>
                <p class="font-weight-bold m-0">{{ title }}</p>
                <p class="m-0">{{ mailId }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name  : 'AppAvatar',
    props : {
        title : {
            type    : String,
            default : ''
        },
        mailId : {
            type    : String,
            default : ''
        },
        img : {
            type : Image
        }
    }
};
</script>
<style scoped>
.avatar-img {
    border-radius: 50%;
    width: 2.9375rem;
    /*width: 71px;*/
}
.half-width{
    width: 50%;
}
.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}
</style>
