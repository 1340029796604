import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon : 'fa fa-home',
            text : 'Dashboard',
            link : '/app/dashboard/'
        },
        {
            icon : 'fa fa-rocket',
            text : 'Projects',
            link : '/app/projects'
        },
        {
            icon : 'fa fa-file-text',
            text : 'Reports',
            link : '/app/reports'
        },
        {
            icon : 'fa fa-file-text',
            text : 'Modules',
            link : '/app/project/modules/fullView'
        },
        {
            icon : 'fa fa-user-circle-o',
            text : 'Admin',
            link : '/app/dashboard/'
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
