<template>
    <div class="page page-sidebar">
        <notifications/>
        <lego-side-bar @link-event="linkEvent" drop-down-animation="zoom" :items="menu"
                       logo-text="Tesuto" :colored="false" has-separator>
            <template #logo>
                <!--                <img src="#" class="my-3" alt="">-->
                Tesuto
            </template>
        </lego-side-bar>
        <div class="page-content-outer bg-2">
            <app-header-component></app-header-component>
            <div class="page-content page-p-t">
                <div class="thin-scrollbar">
                    <transition name="fade"
                                enter-active-class="animated fadeIn"
                                leave-active-class="animated fadeOut" mode="out-in">
                        <router-view>

                        </router-view>
                    </transition>
                </div>
            </div>
            <div class="bg-primary-900 p-3">
                © {{ 2022 }} Tesuto
            </div>
        </div>
    </div>
</template>

<script>
import LegoSideBar from 'lego-framework/src/components/SideNavBar';
import getMenu from '../data/menu';
import urls from '../data/urls';
import axios from 'secure-axios';
import AppHeaderComponent from '@/views/admin/dashboard/components/AppHeaderComponent';

export default {
    components : {
        AppHeaderComponent,
        LegoSideBar
    },
    data () {
        return {
            menu   : getMenu(),
            value  : '',
            search : ''
        };
    },
    methods : {
        linkEvent (name) {
            if (name === 'logout') {
                localStorage.clear();
                this.signOut();
                axios.get(urls.auth.logout);
                this.$router.push('/check-user/');
            }
            console.log(name);
            if (name === 'update-password') {
                this.$router.push('/update-password/');
            }
        }
    },
    metaInfo : {
        titleTemplate : '%s - Tesuto',
        htmlAttrs     : {
            lang : 'en',
            amp  : true
        }
    },
    name : 'MainLayout'
};
</script>
<style scoped lang="scss">
.page-p-t{
    padding-top:6rem;
}
</style>
