<template>
    <div class="p-4 c-app-header">
        <div class="row m-0">
            <div class="col-4">
                <h4 class="font-weight-bold m-0 mt-2 text-primary">Projects</h4>
            </div>
            <div class="col-4">
                <div class="">
                    <validated-input border-radius="2" class="m-0 border-primary" icon="fa fa-search"
                                     placeholder="Search" v-model="search"/>
                </div>
            </div>
            <div class="col-4">
                <app-avatar title="Ajil Raj" :img="avatarSampleImg" mail-id="name@mail.com"></app-avatar>
            </div>
        </div>
<!--        <div class="fl-x">-->
<!--            <div class="w-30r">-->
<!--                <validated-input border-radius="2" class="m-0 border-primary" icon="fa fa-search" placeholder="Search"-->
<!--                                 v-model="search"/>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import AppAvatar from '@components/AppAvatar';
import avatarSample from '../../../../assets/img/avatar/avatar-1.jpg';

export default {
    name       : 'AppHeaderComponent',
    components : { AppAvatar },
    data () {
        return {
            avatarSampleImg : avatarSample
        };
    }
};
</script>

<style scoped lang="scss">

.c-app-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--color-white);
    z-index: 999;
}

</style>
